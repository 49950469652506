import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as Logo } from './assets/logos/ReStringLogo-04.svg';
import './HomePage.css';
import Eleganza from './assets/images/Eleganza_resized.jpg';
import Aubade23 from './assets/images/aubade23.webp';
import Solace from './assets/images/solace.webp';
import LandscapePolaroid from './components/LandscapePolaroid';
import concertImage from './assets/images/concert1.webp';
import WeDidItParty from './assets/images/WeDidItParty.webp';
import Mario from './assets/images/Mario.webp';
import Nocturne23 from './assets/images/Nocturne2023.webp';
import Footer from './components/Footer';
import UpcomingEvent from './components/UpcomingEvent';
// import YasashiiSuisei from './assets/images/YasashiiSuisei.jpg';
// import ChainsawMan from './assets/images/chainsaw_man.jpg';
// import TokyoRevengers from './assets/images/tokyo_revengers.jpg';
import RecitalStudio from './assets/images/recitalstudio.jpg';
import WWGT from './assets/images/WWGT.webp';

const images = [
  { url: WWGT, caption: 'We Will Get There (Singapore National Day 2024 Recording)', link: 'https://youtu.be/9LnC0ML9L98?si=DLRm8RlCqNajJmhh' },
  { url: WeDidItParty, caption: 'We Did It! Party! (Recording)' },
  { url: Mario, caption: 'Mario (Recording)', link: 'https://www.youtube.com/watch?v=J5m1GmX_MAo' },
  { url: Eleganza, caption: 'Eleganza 2024', link: 'https://youtu.be/UFDo2Hz2a2c?si=5mIik42y3dyu_6_z' },
  { url: Aubade23, caption: 'Aubade 2023', link: 'https://youtube.com/playlist?list=PLKSewtSiZXfDvizclYwqiuYXZ_0rE1vp7&si=7t5_oAxxFCGfmxrJ' },
  { url: Solace, caption: 'Solace 2023' },
  { url: Nocturne23, caption: 'Nocturne 2023', link: 'https://www.youtube.com/playlist?list=PLKSewtSiZXfAbUw6eUrTZvR0abS1ImZzK' }
];

const upcomingEvents = [
  {
    image: RecitalStudio,
    title: 'Aubade 2025 (Concert @ Esplanade Recital Studio)',
    subtitle: 'Concert featuring Japanese themed songs such as anime, JPOP, etc',
    description: '18 September 2025'
  },

];

const HomePage = () => {
  const [isHeaderVisible, setHeaderVisible] = useState(false);
  const [isHighlightsVisible, setHighlightsVisible] = useState(false);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const polaroidContainerRef = useRef(null);

  useEffect(() => {
    setHeaderVisible(true);
    setHighlightsVisible(true);
  }, []);

  const scrollLeft = () => {
    if (polaroidContainerRef.current) {
      polaroidContainerRef.current.scrollBy({ left: -520, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (polaroidContainerRef.current) {
      polaroidContainerRef.current.scrollBy({ left: 520, behavior: 'smooth' });
    }
  };

  const handleNextEvent = () => {
    setCurrentEventIndex((prevIndex) => (prevIndex + 1) % upcomingEvents.length);
  };

  const handlePrevEvent = () => {
    setCurrentEventIndex((prevIndex) => (prevIndex - 1 + upcomingEvents.length) % upcomingEvents.length);
  };

  return (
    <div className="container-fluid p-0">
      <div className={`header ${isHeaderVisible ? 'visible' : ''}`} style={{ backgroundImage: `url(${concertImage})` }}>
        <div className="overlay">
          <Logo className="logo" />
          <h1>Welcome to Re:String Guitar Ensemble!</h1>
        </div>
      </div>
      <div className="content">
        <div className="upcoming-events">
          <h1>Upcoming Events</h1>
          <UpcomingEvent 
            event={upcomingEvents[currentEventIndex]} 
            onNext={handleNextEvent} 
            onPrev={handlePrevEvent} 
          />
        </div>
        <div className={`highlights ${isHighlightsVisible ? 'visible' : ''}`}>
          <h1>Past Events</h1>
          <div className="polaroid-wrapper">
            <button className="scroll-button left" onClick={scrollLeft}>{"<"}</button>
            <div className="polaroid-container" ref={polaroidContainerRef}>
              {images.map((image, index) => (
                <a href={image.link} key={index} target="_blank" rel="noopener noreferrer">
                  <div className="polaroid">
                    <LandscapePolaroid
                      imageUrl={image.url}
                      caption={image.caption}
                    />
                  </div>
                </a>
              ))}
            </div>
            <button className="scroll-button right" onClick={scrollRight}>{">"}</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
